import {
  FlowEditorSDK,
  FlowPlatformOptions,
  ComponentRef,
} from '@wix/yoshi-flow-editor';
import {
  PageDefinition,
  PageRef,
  WidgetInstallationType,
} from '@wix/platform-editor-sdk';
import {
  DONATIONS_APP_DEF_ID,
  typWidgetPresetsIds,
} from '@/common/constants/app';
import thankYouPageWidget from '@/components/ThankYouPage/.component.json';
import { initLocaleKeys } from '@/common/utils/locale';
import { isStudio } from '@/editor-app/utils';

export const thankYouPagePageName = 'donation-thank-you-page';

export const addThankYouPage = async (
  editorSDK: FlowEditorSDK,
  options: FlowPlatformOptions,
  t: ReturnType<typeof initLocaleKeys>,
  isRTL: boolean,
) => {
  const isResponsive = options.origin.type === 'RESPONSIVE';

  const pageRef = await addPageAndWidget(
    editorSDK,
    isStudio(options),
    t,
    isRTL,
  );
  if (!isResponsive && pageRef) {
    await addWidgetWithPresets(editorSDK, pageRef, isRTL);
  }
};

const addPageAndWidget = async (
  editorSDK: FlowEditorSDK,
  isStudio: boolean,
  t: ReturnType<typeof initLocaleKeys>,
  isRTL: boolean,
): Promise<PageRef | null> => {
  let pageRef: PageRef | null = null;
  await editorSDK.document.transactions.runAndWaitForApproval(
    'token',
    async () => {
      pageRef = await editorSDK.document.pages.add('', {
        title: t.thankYouPage.pageName(),
        shouldAddMenuItem: false,
        shouldNavigateToPage: false,
        definition: {
          data: {
            managingAppDefId: DONATIONS_APP_DEF_ID,
            pageUriSEO: thankYouPagePageName,
          },
        } as PageDefinition,
      });

      if (isStudio) {
        await editorSDK.pages.navigateTo('', { pageRef });
        const [, sectionRef] = await editorSDK.document.components.getChildren(
          '',
          { componentRef: pageRef },
        );

        await editorSDK.application.appStudioWidgets.addWidget('', {
          containerRef: sectionRef,
          widgetId: thankYouPageWidget.id,
          installationType: 'closed' as WidgetInstallationType,
          layout: {
            height: 1088,
            width: 1152,
            x: 64,
            y: 20,
          },
          scopedPresets: {
            mobile: {
              layout: isRTL ? typWidgetPresetsIds.RTL : typWidgetPresetsIds.LTR,
              style: isRTL ? typWidgetPresetsIds.RTL : typWidgetPresetsIds.LTR,
            },
            desktop: {
              layout: isRTL ? typWidgetPresetsIds.RTL : typWidgetPresetsIds.LTR,
              style: isRTL ? typWidgetPresetsIds.RTL : typWidgetPresetsIds.LTR,
            },
          },
          layouts: {
            componentLayout: {
              minHeight: {
                type: 'px',
                value: 309,
              },
              hidden: false,
              type: 'ComponentLayout',
              height: {
                type: 'auto',
              },
              width: {
                type: 'auto',
              },
            },
            itemLayout: {
              id: '',
              alignSelf: 'stretch',
              margins: {
                top: {
                  type: 'px',
                  value: 20,
                },
                right: {
                  type: 'percentage',
                  value: 5,
                },
                left: {
                  type: 'percentage',
                  value: 5,
                },
                bottom: {
                  type: 'px',
                  value: 20,
                },
              },
              gridArea: {
                rowStart: 0,
                rowEnd: 0,
                columnStart: 0,
                columnEnd: 0,
              },
              justifySelf: 'stretch',
              type: 'GridItemLayout',
            },
          },

          overriddenData: [
            {
              itemType: 'connections',
              dataItem: {
                type: 'ConnectionList',
                items: [
                  {
                    type: 'WixCodeConnectionItem',
                    role: 'donationThankYouPage1',
                  },
                ],
              },
              compId: 'comp-loya4pk71',
              isMobile: false,
            },
          ],
        });
      }
    },
  );
  return pageRef;
};

const addWidgetWithPresets = (
  editorSDK: FlowEditorSDK,
  pageRef: PageRef,
  isRTL: boolean,
) => {
  return editorSDK.application.appStudioWidgets.addWidget('', {
    widgetId: thankYouPageWidget.id,
    layout: {
      height: 309,
      width: 740,
      x: 120,
      y: 120,
    },
    scopedPresets: {
      mobile: {
        layout: isRTL ? typWidgetPresetsIds.RTL : typWidgetPresetsIds.LTR,
        style: isRTL ? typWidgetPresetsIds.RTL : typWidgetPresetsIds.LTR,
      },
      desktop: {
        layout: isRTL ? typWidgetPresetsIds.RTL : typWidgetPresetsIds.LTR,
        style: isRTL ? typWidgetPresetsIds.RTL : typWidgetPresetsIds.LTR,
      },
    },
    installationType: 'closed',
    containerRef: pageRef as ComponentRef,
    overriddenData: [
      {
        itemType: 'connections',
        dataItem: {
          type: 'ConnectionList',
          items: [
            {
              type: 'WixCodeConnectionItem',
              role: 'donationThankYouPage1',
            },
          ],
        },
        compId: 'comp-loya4pk71',
        isMobile: false,
      },
    ],
  });
};
