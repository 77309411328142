import { createDonationCheckout } from '@/common/api/createDonationCheckout';
import { DONATIONS_APP_DEF_ID } from '@/common/constants/app';
import { navigateToCheckout } from './navigateToCheckout';
import { RootStore } from '@/components/DonationForm/types';
import { Frequency } from '@wix/ambassador-donations-v1-donation-campaign/types';
import { validate, getUserInput } from './form';
import { placeDonationSf } from '@wix/bi-logger-wixdonations/v2';
import {
  FedopsInteractions,
  reportFedopsInteraction,
} from '@/components/DonationForm/services/fedopsInteraction';

export const handleDonateClickWrapper = async (rootStore: RootStore) => {
  await reportFedopsInteraction(
    FedopsInteractions.DonationFormSubmit,
    rootStore,
    async () => {
      await handleDonateClick(rootStore);
    },
  );
};

const handleDonateClick = async (rootStore: RootStore) => {
  const { httpClient, $state, biLogger, controllerParams } = rootStore;
  const { flowAPI } = controllerParams;
  const campaignId = $state.campaign?.id;
  if (!campaignId) {
    throw new Error('Campaign id is missing');
  }
  const isFormValid = validate(rootStore);
  if (!isFormValid) {
    return;
  }

  if (flowAPI.environment.isPreview) {
    rootStore.$state.showPremiumError = true;
    return;
  }

  const userInput = getUserInput(rootStore);
  const { amount, frequency, comment } = userInput;
  const calFrequency = frequency ? frequency : Frequency.ONE_TIME;

  biLogger!.report(
    placeDonationSf({
      frequency: calFrequency,
      amount,
    }),
  );

  const donationLineItem = {
    catalogReference: {
      appId: DONATIONS_APP_DEF_ID,
      catalogItemId: campaignId,
      options: {
        amount,
        frequency: calFrequency,
      },
    },
    quantity: 1,
  };

  const checkout = await createDonationCheckout(httpClient, {
    donationLineItem,
    comment,
    flowAPI,
  });
  await navigateToCheckout(rootStore, checkout?.id!, campaignId);
};
