import { ILocaleKeys } from '@/locale-keys';
import { MultiCurrencyPrice } from '@wix/ambassador-donations-v1-donation-campaign/build/cjs/types.impl';

export const stringValueToNumber = (value?: string) => {
  const amountValue = parseFloat(value ?? '');
  if (isNaN(amountValue)) {
    return 0;
  }
  return amountValue;
};
const positiveNumberPattern = '^\\d+(?:\\.\\d+)?$';
const positiveNumberRegExp = new RegExp(positiveNumberPattern, 'i');
const twoDecimalsPattern = '^\\d+(?:\\.\\d{0,2})?$';
const twoDecimalsRegExp = new RegExp(twoDecimalsPattern, 'i');

export const getErrorMessage = (
  value: string | undefined,
  localeKeys: ILocaleKeys,
  limits: { min?: MultiCurrencyPrice; max?: MultiCurrencyPrice },
) => {
  if (!value) {
    return localeKeys.donationForm.otherAmount.empty.errorMessage();
  } else {
    const minValue = limits.min?.amount ? Number(limits.min.amount) : 0;
    const maxValue = limits.max?.amount ? Number(limits.max.amount) : Infinity;
    const numberValue = parseFloat(value);
    if (isNaN(value as any) || !positiveNumberRegExp.test(value)) {
      return localeKeys.donationForm.otherAmount.specialCharacters.errorMessage();
    } else if (!twoDecimalsRegExp.test(value)) {
      return localeKeys.donationForm.otherAmount.decimalLimit.errorMessage();
    } else if (numberValue === 0) {
      return localeKeys.donationForm.otherAmount.empty.errorMessage();
    } else if (limits.min && numberValue < minValue) {
      return localeKeys.donationForm.otherAmount.minLimit.errorMessage({
        minAmount: limits.min?.formattedAmount,
      });
    } else if (limits.max && numberValue > maxValue) {
      return localeKeys.donationForm.otherAmount.maxLimit.errorMessage({
        maxAmount: limits.max?.formattedAmount,
      });
    } else {
      return '';
    }
  }
};
