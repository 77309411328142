import model from './model';
import { ComponentIds as Ids } from '@/components/FrequencyWidget/constants';
import OptionsList from '@/components/FrequencyWidget/OptionsList/OptionsList.bind';
import { Frequency } from '@wix/ambassador-donations-v1-donation-campaign/build/cjs/types.impl';
import { makeAutoObservable } from 'mobx';
import { FrequencyState } from './types';
import { getTranslationOrUserText } from '@/common/utils/getTranslationOrUserText';
import { initLocaleKeys } from '@/common/utils/locale';
import { donationFrequencyChangeInSf } from '@wix/bi-logger-wixdonations/v2';

export default model.createController(
  ({ $props, $bindAll, $widget, $w, flowAPI }) => {
    const localeKeys = initLocaleKeys(flowAPI.translations.i18n);
    const fireChangeEvent = (value: Frequency) => {
      flowAPI.bi?.report(donationFrequencyChangeInSf({}));
      $widgetState.selectedValue = value;
      $widget.fireEvent('change', value);
    };
    const $widgetState: FrequencyState = makeAutoObservable<FrequencyState>({
      selectedValue: undefined,
      get value() {
        return (
          $widgetState.selectedValue ??
          ($props.defaultValue as Frequency | undefined)
        );
      },
      get selectedIndex() {
        const index = $props.options.findIndex(
          ({ value }) => value === $widgetState.value,
        );
        return index >= 0 ? index : 0;
      },
      reset() {
        $widgetState.selectedValue = undefined;
      },
    });
    return {
      pageReady: async () => {
        const frequencyStore = {
          $props,
          $w,
          fireChangeEvent,
          $widgetState,
        };
        $bindAll({
          ...OptionsList(frequencyStore),
          [Ids.ListBox]: {
            accessibility: {
              ariaAttributes: {
                labelledBy: () => $w(Ids.Label),
              },
              role: () => 'radiogroup',
            },
          },
          [Ids.Label]: {
            deleted: () => !$props.shouldShowTitle,
            text: () =>
              getTranslationOrUserText(
                $w(Ids.Label).text,
                localeKeys.donationForm.frequency.label.defaultText(),
                $props.label,
              ),
          },
        });
      },
      exports: {
        focus: () => ($w(Ids.Repeater) as any).focus(),
        isValid: () => {
          return $w(Ids.Repeater).selectedIndex >= 0;
        },
        getValue: () => {
          const { value } = $props.options[$w(Ids.Repeater).selectedIndex];
          return value;
        },
        reset: () => {
          $widgetState?.reset();
        },
      },
    };
  },
);
